@orange: #f4aa14;
@fontFamily: PingFangSC-Regular, PingFang SC;
@white: #fff;
@black: #222;
@fontfamily:pingfangsc-semibold, PingFang SC;

.dark {
  // * {
  //   -moz-user-select: -moz-none;
  //   -moz-user-select: none;
  //   -o-user-select: none;
  //   -khtml-user-select: none;
  //   -webkit-user-select: none;
  //   -ms-user-select: none;
  //   user-select: none;
  // }
  ::selection {
    color: #fff;
    background: @orange;
}
  ul,
  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .ant-layout {
    background: #fff;
  }
  //大title
  .bigTtile {
    font-size: 32px;
    font-family: @fontFamily;
    font-weight: 600;
    color: @black;
    line-height: 32px;
  }
  // button样式
  .ant-btn {
    line-height: 32px;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    height: 36px;
    padding: 0 24px;
    font-size: 14px;
    border-radius: 24px;
    font-family: @fontFamily;
    color: @white;
    background: transparent;
    border: 2px solid @white;
  }
  .ant-btn:active,
  .ant-btn:hover {
    color: @white;
    background: transparent;
    border-color: @white;
  }
  .ant-btn-primary,
  .ant-btn-primary:active,
  .ant-btn-primary:hover {
    background: @orange;
    border-radius: 20px;
    border: 2px solid @orange;
    color: @black;
  }
  .ant-btn-link {
    background: @orange;
    border-radius: 20px;
    border: 2px solid @orange;
    color: @black;
  }
  .ant-btn-detail,
  .ant-btn-detail:active,
  .ant-btn-detail:hover {
    background: @white;
    padding: 0 30px;
    height: 44px;
    box-shadow: 8px 8px 12px 0px rgba(0, 0, 0, 0.2);
    border-radius: 22px;
    color: @black;
  }
  //菜单样式
  .ant-menu.ant-menu-dark .ant-menu-item-selected,
  .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background: linear-gradient(
      180deg,
      rgba(213, 157, 34, 0) 0%,
      rgba(243, 175, 30, 0.29) 100%
    );
  }
  .ant-menu.ant-menu-dark,
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    color: rgba(255, 255, 255, 0.65);
    background: #001529;
  }

  //carousel
  .ant-carousel .slick-dots li {
    position: relative;
    display: inline-block;
    flex: 0 1 auto;
    box-sizing: content-box;
    width: 10px;
    height: 4px;
    margin-right: 4px;
    margin-left: 4px;
    padding: 0;
    text-align: center;
    text-indent: -999px;
    vertical-align: top;
    transition: all 0.5s;
    transform: skew(-30deg);
  }
  .ant-carousel .slick-dots li.slick-active {
    position: relative;
    display: inline-block;
    flex: 0 1 auto;
    box-sizing: content-box;
    width: 24px;
    height: 4px;
    margin-right: 4px;
    margin-left: 4px;
    padding: 0;
    text-align: center;
    text-indent: -999px;
    vertical-align: top;
    transition: all 0.5s;
  }
  .ant-carousel .slick-dots li.slick-active button {
    display: block;
    width: 100%;
    height: 4px;
    padding: 0;
    color: transparent;
    font-size: 0;
    background: #f4aa14;
    border: 0;
    border-radius: 1px;
    outline: none;
    cursor: pointer;
    transition: all 0.5s;
  }
  .ant-carousel .slick-dots li button {
    display: block;
    width: 100%;
    height: 4px;
    padding: 0;
    color: transparent;
    font-size: 0;
    background: #fff;
    border: 0;
    border-radius: 1px;
    outline: none;
    cursor: pointer;
    opacity: 1;
  }
  .ant-carousel .slick-dots-bottom {
    bottom: 45px;
  }
  //头部样式重置
  .ant-menu {
    -webkit-appearance: none; //阴影由该属性开启
    -moz-appearance: none;
    appearance: none;
    -webkit-box-shadow: none;
    box-shadow: none !important;
    font-size: 16px;
  }
  .ant-layout-header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    color: rgba(0, 0, 0, 0.85);
    line-height: 64px;
    background: transparent;
    width: 100%;
    padding: 0 0;
    .header {
      width: 100%;
      height: 64px;
      // border-bottom: 1px solid rgba(255,255,255,0.7);
      .header-middle {
        width: 1280px;
        margin: 0 auto;
        display: flex;
        height: 64px;
        justify-content: space-between;
        .ant-menu-item:not(:last-child) {
          margin-bottom: 0;
        }
        .ant-menu-inline > .ant-menu-item {
          margin-top: 0px;
          height: 100%;
          line-height: 64px;
          padding: 0 0;
          text-align: center;
          width: 96px;
        }
        .ant-menu-item::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          border-right: 0px;
          transform: scaleY(0.0001);
          opacity: 0;
          transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
            opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
          content: "";
        }
        .ant-menu-item a {
          color: @white;
        }
        .ant-menu-item-selected a,
        .ant-menu-item-selected a:hover,
        .ant-menu-item-selected span .ant-menu-item-selected span {
          color: @orange;
        }
        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
        .ant-menu-item:active {
          background: linear-gradient(
            180deg,
            rgba(213, 157, 34, 0) 0%,
            rgba(243, 175, 30, 0.29) 100%
          );
          color: @orange;
          border-bottom: none;
        }
        .ant-menu-item-selected {
          color: @orange;
          background: none;
          border-bottom: none;
        }
        .ant-menu-submenu-active > .ant-menu-submenu-title,
        .ant-menu-submenu-active > .ant-menu-submenu-title:hover {
          color: @orange;
          background: linear-gradient(
            180deg,
            rgba(213, 157, 34, 0) 0%,
            rgba(243, 175, 30, 0.29) 100%
          );
          border-bottom: none;
        }
        .ant-menu-inline {
          border-right: none;
        }
        .ant-menu {
          display: flex;
          box-sizing: border-box;
          margin: 0;
          padding: 0;
          font-variant: tabular-nums;
          line-height: 64px;
          margin-bottom: 0;
          padding-left: 0;
          color: #fff;
          font-size: 14px;
          text-align: left;
          list-style: none;
          background: transparent;
          outline: none;
          -webkit-appearance: none; //阴影由该属性开启

          -moz-appearance: none;
          appearance: none;

          -webkit-box-shadow: none;
          box-shadow: none !important;
          // transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
          border-bottom: none;
        }
  
        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
        .ant-menu-horizontal > .ant-menu-submenu {
          width: 96px;
          height: 64px;
          margin: 0 0;
          padding: 0 0;
          border-bottom: none;
          text-align: center;
          top: 0;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 14px;
          line-height: 64px;
          padding-left: 0px;
          margin-left: 10px;
        }
        .ant-menu-horizontal > .ant-menu-submenu-selected {
         
          color: @orange !important;
          background: linear-gradient(180deg, rgba(213, 157, 34, 0) 0%, rgba(243, 175, 30, 0.29) 100%) !important;
        
        }
        .ant-menu-horizontal > .ant-menu-item-only-child.ant-menu-item-selected  {
          color: @orange !important;
          background: linear-gradient(180deg, rgba(213, 157, 34, 0) 0%, rgba(243, 175, 30, 0.29) 100%) !important; 
        }
      }
    }
  }
  .ant-menu-item-active {
    background: transparent;
  }
  .ant-menu-submenu {
    width: 100%;
    .ant-menu {
      display: flex;
      justify-content: center;
      height: 77px;
      line-height: 70px;
    }
    .ant-menu-vertical .ant-menu-item,
    .ant-menu-item-selected {
      margin-bottom: 0;
      height: 77px;
      line-height: 70px;
    }
    .ant-menu-vertical .ant-menu-item {
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 0 20px;
    }
    .ant-menu-item-selected {
      color: @orange;
      background: transparent;
    }
    .ant-menu-item-selected:active,
    .ant-menu-item-selected,
    .ant-menu-item-selected a {
      color: @orange;
      background: transparent;
    }
    .ant-menu-item a:hover,
    .ant-menu-item-selected a,
    .ant-menu-item-selected a:hover {
      color: @orange;
      background: transparent;
    }
  }
  .ant-menu-submenu-popup::before {
    position: absolute;
    top: -7px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 1;
    content: " ";
    background: #fff;
  }

  //footer
  .ant-layout-footer {
    width: 100%;
    height: 251px;
    padding: 0px 0px;
    color: @white;
    font-size: 14px;
    background: #182c54;
    .footer_font {
      height: 187px;
      width: 100%;
      .fonter_content {
        width: 1200px;
        height: 187px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .left {
          height: 187px;
          display: flex;
          align-items: center;
          .ditu {
            width: 153px;
            height: 88px;
          }
          .gap {
            width: 1px;
            height: 64px;
            border-left: 1px solid #fff;
            margin: 0 40px;
            opacity: 0.2;
          }
          .des {
            height: 74px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            opacity: 0.8;
            span {
              flex: 1;
            }
          }
        }
        .code {
          align-self: flex-end;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-family: @white;
          img {
            width: 88px;
            height: 88px;
          }
          div {
            line-height: 12px;
            margin-top: 10px;
            opacity: 0.7;
          }
        }
      }
    }
    .Copyright {
      width: 100%;
      height: 64px;
      line-height: 64px;
      background: rgba(0, 0, 0, 0.25);
      font-size: 12px;
      text-align: center;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #fff;
      .pdfClick {
        color: #fff;
        opacity: 0.5;
        cursor: pointer;
        &:hover {
          color: #fff;
          opacity: 1;
          cursor: pointer;
        }
      }
    }
  }
  .solid {
    width: 860px;
    height: 240px;
    background: #ffffff;
    box-shadow: 20px 20px 20px 0px rgba(0, 0, 0, 0.19);
    border-radius: 233px;
    display: inline-block;
    padding: 10px;
    margin-top: 30px;
  }

  // input 表单
 .ant-form-item-explain-error {
   text-align: left; //错误提示
 }
  .ant-form-item-label > label::after {
    content: "";
    position: relative;
    top: -0.5px;
    margin: 0 8px 0 2px;
  }
  .vscodeForm .ant-form-item{
    align-items: normal !important;
  }
  .ant-form-item {
    width: 640px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    list-style: none;
    vertical-align: top;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3c3c3c;
    line-height: 44px;
    &:nth-last-child(3) {
      .ant-form-item-control {
        text-align: left;
        .ant-input {
          width: 360px !important;
        }
      }
    }
    .ant-form-item-label {
      width: 100px;
      margin-right: 40px;
    }

    .ant-form-item-control,
    .ant-form-item-control-input {
      display: inline-block;
    }
    .ant-input {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      position: relative;
      display: inline-block;
      min-width: 0;
      padding: 4px 11px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      line-height: 34px;
      background-color: #fff;
      background-image: none;
      transition: all 0.3s;
      width: 500px;
      background: rgba(216, 216, 216, 0);
      border: 1px solid #c4c7cc;
    }
    .ant-input:focus,
    .ant-input:hover,
    .ant-input-focused {
      border-color: @orange;
      border-right-width: none !important;
      outline: 0;
      box-shadow: none;
      border: 1px solid @orange;
    }
  }

  // modal

  .ant-modal-content {
    .ant-modal-header {
      height: 48px;
      background: #182c54;
      .ant-modal-title {
        margin: 0;
        word-wrap: break-word;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 16px;
        text-align: center;
      }
    }
    .ant-modal-close {
      color: #fff;
    }
  }
  .ant-anchor-ink-ball.visible {
    display: none;
  }
  #pro {
    .ant-affix {
      margin-top: 64px;
    }
  }
  .ant-anchor-wrapper {
    max-height: 100vh;
    display: flex;
    justify-content: space-between;
    .ant-anchor {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 72px;
      border-bottom: 1px solid #e6e6e6;
      .ant-anchor-link {
        padding: 0 0 0 0;
        line-height: 72px;
        height: 72px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        margin: 0 30px;
        border-bottom: 2px solid transparent;
      }
      .ant-anchor-link-active > .ant-anchor-link-title,
      .ant-anchor-link-active > .ant-anchor-link-title:hover {
        color: #f4aa14;
      }
      .ant-anchor-link-active {
        border-bottom: 2px solid #f4aa14 !important;
      }

      a:hover {
        color: #f4aa14;
      }
    }
  }
  //置顶
  .ant-back-top-content {
    width:64px;
    height: 64px;
    color:#222;
background: #FFFFFF;
box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, 0.2);
border-radius: 32px;
.ant-back-top-icon {
  line-height: 64px;
  font-size: 36px;
}
&:hover {
  color:@orange
}
  }

  // confrim
  .ant-modal-confirm-btns {
    float:none;
    margin-bottom: 32px;
    text-align: center;
    .ant-btn:first-child {
      display: none;
    }
  }
}

@primary-color: #1DA57A;