/* Make clicks pass-through */
#nprogress {
    pointer-events: none;
}

#nprogress .bar {
    background: #29d;

    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;

    width: 100%;
    height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #29d, 0 0 5px #29d;
    opacity: 1.0;

    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    margin-top: -25px;
    margin-left: -25px;
}

#nprogress .spinner-icon {
    width: 40px;
    height: 40px;
    box-sizing: border-box;

    border: solid 4px transparent;
    border-top-color: #f4aa14;
    border-left-color: #f4aa14;
    border-radius: 50%;

    -webkit-animation: nprogress-spinner 800ms linear infinite;
    animation: nprogress-spinner 800ms linear infinite;
}

.nprogress-custom-parent {
    overflow: hidden;
    position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
    position: absolute;
}

@-webkit-keyframes nprogress-spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes nprogress-spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


/*#nprogress .spinner-icon {*/
/*    position: fixed;*/
/*    width: 50px;*/
/*    height: 50px;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    margin-top: -25px;*/
/*    margin-left: -25px;*/
/*    background-color: #FFF5A5;*/
/*    transform: translateX(0px) translateY(0px) rotate(0deg);*/
/*    animation: mainAnimation 2.6s ease 0s infinite forwards;*/
/*    z-index: 1031;*/
/*    &:after {*/
/*        content: '';*/
/*        display: inline-block;*/
/*        position: absolute;*/
/*        width: 50px;*/
/*        height: 50px;*/
/*        top: 0;*/
/*        left: -50px;*/
/*        background-color: #FFF5A5;*/
/*        transform-origin: top right;*/
/*        transform: translateX(0px) translateY(0px) rotate(0deg);*/
/*        animation: secundaryAnimation 2.6s ease 0s infinite forwards;*/
/*    }*/
/*}*/

/*#nprogress .spinner::before{*/
/*    content: '';*/
/*    position: fixed;*/
/*    width: 100px;*/
/*    height: 10px;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    margin-top: 50px;*/
/*    margin-left: -75px;*/
/*    border-radius: 50%;*/
/*    background-color: #95a5a6;*/
/*    transform: translateX(0px) translateY(0px) rotate(0deg);*/
/*    animation: shadowAnimation 2.6s ease 0s infinite forwards,*/
/*    float 5s ease-in-out 0s infinite forwards;*/
/*    filter: blur(5px);*/
/*    z-index: 1;*/
/*}*/
/*@keyframes mainAnimation {*/
/*    0% {*/
/*        width: 50px;*/
/*        transform: translateX(0px) translateY(0px) rotate(0deg);*/
/*    }*/
/*    20% {*/
/*        width: 50px;*/
/*        transform: translateX(0px) translateY(0px) rotate(0deg);*/
/*    }*/
/*    40% {*/
/*        width: 150px;*/
/*        transform: translateX(-50px) translateY(0px) rotate(0deg);*/
/*    }*/
/*    60% {*/
/*        width: 150px;*/
/*        transform-origin: bottom right;*/
/*        transform: translateX(-150px) translateY(0px) rotate(90deg);*/
/*    }*/
/*    80% {*/
/*        width: 50px;*/
/*        transform: translateX(-25px) translateY(0px) rotate(90deg);*/
/*    }*/
/*    100% {*/
/*        width: 50px;*/
/*        transform: translateX(-50px) translateY(0px) rotate(90deg);*/
/*    }*/
/*}*/
/*@keyframes secundaryAnimation {*/
/*    0% {*/
/*        transform: translateX(0px) translateY(0px) rotate(0deg);*/
/*    }*/
/*    20% {*/
/*        transform: translateX(0px) translateY(0px) rotate(180deg);*/
/*    }*/
/*    40% {*/
/*        transform: translateX(0px) translateY(0px) rotate(180deg);*/
/*    }*/
/*    60% {*/
/*        transform: translateX(0px) translateY(0px) rotate(0deg);*/
/*    }*/
/*    80% {*/
/*        transform: translateX(0px) translateY(0px) rotate(0deg);*/
/*    }*/
/*    100% {*/
/*        transform: translateX(0px) translateY(0px) rotate(180deg);*/
/*    }*/
/*}*/
/*@keyframes shadowAnimation {*/
/*    0% {*/
/*        width: 150px;*/
/*        transform: translateX(-25px) translateY(0px) rotate(0deg);*/
/*    }*/
/*    20% {*/
/*        width: 75px;*/
/*        transform: translateX(37.5px) translateY(0px) rotate(0deg);*/
/*    }*/
/*    40% {*/
/*        width: 200px;*/
/*        transform: translateX(-25px) translateY(0px) rotate(0deg);*/
/*    }*/
/*    60% {*/
/*        width: 75px;*/
/*        transform: translateX(37.5px) translateY(0px) rotate(0deg);*/
/*    }*/
/*    80% {*/
/*        width: 75px;*/
/*        transform: translateX(37.5px) translateY(0px) rotate(0deg);*/
/*    }*/
/*    100% {*/
/*        width: 150px;*/
/*        transform: translateX(-25px) translateY(0px) rotate(0deg);*/
/*    }*/
/*}*/
/*@keyframes float {*/
/*    0% {*/
/*        top: 50%;*/
/*    }*/
/*    50% {*/
/*        top: 51%;*/
/*    }*/
/*    100% {*/
/*        top: 50%;*/
/*    }*/
/*}*/

/*#nprogress .spinner {*/
/*    position: fixed;*/
/*    width: 100px;*/
/*    height: 100px;*/
/*    font-size: 20px;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    !*margin-top: -100px;*!*/
/*    !*margin-left: -100px;*!*/
/*    z-index: 999;*/
/*}*/

/*#nprogress .spinner-icon {*/
/*    &, &::before, &::after {*/
/*        display: block;*/
/*        content: '';*/
/*        position: absolute;*/
/*        bottom: 0;*/
/*        background: #FFEFAF;*/
/*        transform-origin: 50% 50%;*/
/*    }*/


/*    &::before {*/
/*        position: absolute;*/
/*        top: -1em;*/
/*        left: -0.4em;*/
/*        width: 1em;*/
/*        height: 2.5em;*/
/*        transform: rotate(-45deg);*/
/*        !*@include border-bottom-left-radius(2em);*!*/
/*        !*@include border-top-right-radius(2em);*!*/
/*        border-bottom-left-radius: 2em;*/
/*        border-top-right-radius: 2em;*/
/*        animation: loading3b 2s infinite;*/
/*    }*/

/*    & {*/
/*        background: #F7A072;*/
/*        height: 3em;*/
/*        width: 0.2em;*/
/*        !*@include border-bottom-right-radius(2em);*!*/
/*        !*@include border-bottom-left-radius(2em);*!*/
/*        border-bottom-left-radius: 2em;*/
/*        border-bottom-right-radius: 2em;*/
/*    }*/

/*    &::after {*/
/*        position: absolute;*/
/*        top: -1em;*/
/*        left: -0.4em;*/
/*        width: 1em;*/
/*        height: 2.5em;*/
/*        transform: rotate(45deg);*/
/*        !*@include border-bottom-left-radius(2em);*!*/
/*        !*@include border-top-right-radius(2em);*!*/
/*        border-bottom-left-radius: 2em;*/
/*        border-top-right-radius: 2em;*/
/*        animation: loading3a 2s infinite;*/
/*    }*/
/*}*/

/*@keyframes loading3b {*/
/*    0% {*/
/*        transform: rotate(-45deg);*/
/*    }*/
/*    100% {*/
/*        transform: rotate(680deg);*/
/*    }*/
/*}*/

/*@keyframes loading3a {*/
/*    0% {*/
/*        transform: rotate(45deg);*/
/*    }*/
/*    100% {*/
/*        transform: rotate(765deg);*/
/*    }*/
/*}*/

